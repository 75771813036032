<template>
  <v-card max-width="600">
    <v-img
      :src=imgMap
      alt="No se pudo obtener el mapa"
    ></v-img>
  </v-card>
</template>

<script>
import axios from 'axios'
import { config } from "@/api_utils/config.js"
import { addressesMixin } from '@/mixins/addresses.js'
import { mapState } from 'vuex'

export default {
  mixins: [addressesMixin],
  props: {
    customerAddress: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      googleMapsURL: 'https://maps.googleapis.com/maps/api/staticmap?',
      imgMap: null,
      center: '18.8396973000000000,-97.1149005000000000',
      zoom: '15',
      size: '600x600',
      markerColor: 'red',
      markerLocation: '',
      key: config.API_KEY
    }
  },
  mounted() {
    this.getImgMap()
  },
  computed: {
    ...mapState('usuario', ['userAddress']),
  },
  methods: {
    async getImgMap() {
      this.imgMap = this.buildURL()
    },
    buildURL() {
      if (this.customerAddress) {
        const coordinates = this.customerAddress.latitud && this.customerAddress.longitud 
          ? `${this.customerAddress.latitud},${this.customerAddress.longitud}` 
          : `${this.userAddress.latitud},${this.userAddress.longitud}`
        const strAddress = this.customerAddress.latitud === null && this.customerAddress.longitud === null 
          ? `${this.addressToString(this.customerAddress)}` 
          : `${this.addressToString(this.userAddress)}`

        this.center = coordinates ? coordinates : strAddress
        this.markerLocation = '&markers=color:' + this.markerColor
        + '|' + this.center
      }
      
      const url = this.googleMapsURL 
        + 'center=' + this.center
        + '&zoom=' + this.zoom 
        + '&size=' + this.size
        + this.markerLocation
        + '&key=' + this.key
      return url
    }
  }
}
</script>

<style>

</style>